exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-projects-jsx": () => import("./../../../src/pages/projects.jsx" /* webpackChunkName: "component---src-pages-projects-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-templates-project-jsx-content-file-path-src-content-projects-mpatlas-index-mdx": () => import("./../../../src/templates/Project.jsx?__contentFilePath=/opt/build/repo/src/content/projects/mpatlas/index.mdx" /* webpackChunkName: "component---src-templates-project-jsx-content-file-path-src-content-projects-mpatlas-index-mdx" */),
  "component---src-templates-project-jsx-content-file-path-src-content-projects-nabat-workshop-index-mdx": () => import("./../../../src/templates/Project.jsx?__contentFilePath=/opt/build/repo/src/content/projects/nabat_workshop/index.mdx" /* webpackChunkName: "component---src-templates-project-jsx-content-file-path-src-content-projects-nabat-workshop-index-mdx" */),
  "component---src-templates-project-jsx-content-file-path-src-content-projects-nzdoc-index-mdx": () => import("./../../../src/templates/Project.jsx?__contentFilePath=/opt/build/repo/src/content/projects/nzdoc/index.mdx" /* webpackChunkName: "component---src-templates-project-jsx-content-file-path-src-content-projects-nzdoc-index-mdx" */),
  "component---src-templates-project-jsx-content-file-path-src-content-projects-psmfc-estuaries-explorer-index-mdx": () => import("./../../../src/templates/Project.jsx?__contentFilePath=/opt/build/repo/src/content/projects/psmfc_estuaries_explorer/index.mdx" /* webpackChunkName: "component---src-templates-project-jsx-content-file-path-src-content-projects-psmfc-estuaries-explorer-index-mdx" */),
  "component---src-templates-project-jsx-content-file-path-src-content-projects-sarp-connectivity-index-mdx": () => import("./../../../src/templates/Project.jsx?__contentFilePath=/opt/build/repo/src/content/projects/sarp_connectivity/index.mdx" /* webpackChunkName: "component---src-templates-project-jsx-content-file-path-src-content-projects-sarp-connectivity-index-mdx" */),
  "component---src-templates-project-jsx-content-file-path-src-content-projects-sarp-project-tracker-index-mdx": () => import("./../../../src/templates/Project.jsx?__contentFilePath=/opt/build/repo/src/content/projects/sarp_project_tracker/index.mdx" /* webpackChunkName: "component---src-templates-project-jsx-content-file-path-src-content-projects-sarp-project-tracker-index-mdx" */),
  "component---src-templates-project-jsx-content-file-path-src-content-projects-secas-index-mdx": () => import("./../../../src/templates/Project.jsx?__contentFilePath=/opt/build/repo/src/content/projects/secas/index.mdx" /* webpackChunkName: "component---src-templates-project-jsx-content-file-path-src-content-projects-secas-index-mdx" */)
}

